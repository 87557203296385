<template>
    <div class="p-4">
        <Grid/>
    </div>
</template>

<script>
import Grid from './Grid.vue';
export default {
    name : "GridContainer",
    components: {
        Grid
    }
}
</script>

<style lang="scss" scoped>
</style>