<template>
    <div>
        <div :class="classes" ref="grid" >
            <Card :key="index" v-for="(tile, index) in tiles" :tiles="tiles[index]"/>
    </div>
</div>
</template>

<script>
import Card from './Card.vue';
import { wrapGrid } from '../../node_modules/animate-css-grid';
export default {
    name : "Grid",
    components : {
        Card
    },
    data() {
        return {
            tiles : [
                { 
                    id : 1,
                    title : "About Me",
                    class : "aboutme",
                    heading : "Hi, I'm Simone, nice to meet you!",
                    gridArea : "1 / 1 / span 1 / span 1",
                    expandable : true,
                    bgColor : "#ffffff",
                    imgSrc : "https://c.tenor.com/jjADcY68aA0AAAAd/waving-bear-hi.gif",
                    ghLink : "",
                    demoLink : "",
                    paragraph : 'I am a Full Stack Web Developer based in Pistoia, Italy. After many years spent in the performative arts I thought that I could use coding to  channel my creativity, and I discovered a bottomless well of tools, inspiration, and amazing things. So here I am, joining the party. I like creating things that are easy to use and pleasant to see. My main hobby is going knee-deep into Wikipedia rabbit holes, but I also find time for chess and watching stand-up comedy.'
                },
                { 
                    id : 2,
                    title : "Skills",
                    class : "skills",
                    heading : "My Stack",
                    gridArea: "1 / 2 / span 3 / span 3",
                    expandable : true,
                    bgColor : "#dd0100",
                    imgSrc : "",
                    ghLink : "",
                    demoLink : "",
                    paragraph : ""
                },
                { 
                    id : 3,
                    title : "",
                    class : "empty4",
                    gridArea: "1 / 5 / span 3 / span 2",
                    expandable : false,
                    bgColor : "#dd000091",
                    // bgColor : "#dd0100"
                    imgSrc : "",
                    ghLink : "",
                    demoLink : "",
                    paragraph : ""
                },
                { 
                    id : 4,
                    title : "CV",
                    class : "cv",
                    heading : "",
                    gridArea: "1 / 7 / span 2 / span 1",
                    expandable : true,
                    bgColor : "#ffffff",
                    imgSrc : "",
                    ghLink : "",
                    demoLink : "",
                    paragraph : ""
                },
                { 
                    id : 5,
                    title : "Weather App",
                    class : "weatherapp",
                    heading : "A Vue.js animated app",
                    gridArea: "2 / 1 / span 2 / span 1",
                    expandable : true,
                    bgColor : "#225095",
                    imgSrc : require("../assets/img/weatherapp_gif.gif"),
                    ghLink : "https://github.com/elmurie/weatherapp",
                    demoLink : "https://elmurie-weather-app.netlify.app/",
                    paragraph : "This is a mobile weather app that displays the current weather anywhere in the world using OpenWeather's API. The app's homepage displays either a day background image or a night one, according to the time of the day. To each weather event corresponds an animation. The animated events are : clouds, rain, snow, fog and thunderstorm.",
                    technologies : 'VUE CLI, Animejs'
                },
                { 
                    id : 6,
                    title : "",
                    class : "empty1",
                    gridArea: "3 / 7 / span 3 / span 1",
                    expandable : false,
                    bgColor : "#225095",
                    imgSrc : "",
                    ghLink : "",
                    demoLink : "",
                    paragraph : ""
                },
                { 
                    id : 7,
                    title : "PlayStation Clone",
                    class : "playStation",
                    heading : "A reproduction of the PS Store website",
                    gridArea: "4 / 1 / span 2 / span 2",
                    expandable : true,
                    bgColor : "#ffffff",
                    imgSrc : require("../assets/img/playstation.gif"),
                    ghLink : "https://github.com/elmurie/htmlcss-playstation",
                    demoLink : "https://elmurie.github.io/htmlcss-playstation",
                    paragraph : "I have recreated Playstation Store's responsive homepage.",
                    technologies : "HTML5, CSS3, Bootstrap"
                },
                { 
                    id : 8,
                    title : "Spotify Web Clone",
                    class : "spotify",
                    heading : "A reproduction of Spotify Web",
                    gridArea: "4 / 3 / span 2 / span 2",
                    expandable : true,
                    bgColor : "#ffffff",
                    imgSrc : require("../assets/img/spotify.gif"),
                    ghLink : "https://github.com/elmurie/html-css-spotifyweb",
                    demoLink : "https://elmurie.github.io/html-css-spotifyweb",
                    paragraph : "A clone of Spotify Web in responsive CSS.",
                    technologies : "HTML, CSS"
                },
                { 
                    id : 9,
                    title : "WP Theme",
                    class : "wptheme",
                    heading : "A Wordpress theme",
                    gridArea: "4 / 5 / span 2 / span 2",
                    expandable : true,
                    bgColor : "#fac901",
                    imgSrc : require("../assets/img/wp_theme.gif"),
                    ghLink : "https://github.com/elmurie/proj-html-vuejs",
                    demoLink : "https://morieri-theme.netlify.app/",
                    paragraph : "A clone of a WordPress theme found on ThemeForest. You get sympathy points if you find the easter egg 😉",
                    technologies : "Vue.js, vue-kinesis"
                },
                { 
                    id : 10,
                    title : "WhatsApp Clone",
                    class : "whatsApp",
                    heading : "A chat app",
                    gridArea: "6 / 1 / span 2 / span 3",
                    expandable : true,
                    bgColor : "#fac901",
                    imgSrc : require("../assets/img/boolzap.gif"),
                    ghLink : "https://github.com/elmurie/vue-boolzapp",
                    demoLink : "https://elmurie.github.io/vue-boolzapp",
                    paragraph : "In this responsive WhatsApp clone you can select between the different chats, filter them by typing the name in the searchbar, add a message to the chat and get a response.",
                    technologies : "HTML, CSS, Vue.js"
                },
                { 
                    id : 11,
                    title : "Netflix Clone",
                    class : "netflix",
                    heading : "A reproduction of Netflix",
                    gridArea: "6 / 4 / span 1 / span 3",
                    expandable : true,
                    bgColor : "#ffffff",
                    imgSrc : require("../assets/img/boolflix.gif"),
                    ghLink : "https://github.com/elmurie/vue-boolflix",
                    demoLink : "https://morieri-boolflix.netlify.app/",
                    paragraph : "This is a Netflix clone that shows movies and TV Series using TheMovieDB's API. Hovering over a card will display the corresponding data. Intended for Italian speakers only, sorry 😅.The website grabs the trending movies and TV series from the API on page load, but you can search for your favourite using the search bar.",
                    technologies: "VUE CLI, Bootstrap"
                },
                { 
                    id : 12,
                    title : "",
                    class : "empty2",
                    gridArea: "6 / 7 / span 1 / span 1",
                    expandable : false,
                    bgColor : "#ffffff",
                    imgSrc : "",
                    ghLink : "",
                    demoLink : "",
                    paragraph : ""
                },
                { 
                    id : 13,
                    title : "Snake Minigame",
                    class : "snake",
                    heading : "Snake Minigame",
                    gridArea: "7 / 4 / span 1 / span 3",
                    expandable : true,
                    bgColor : "#dd0100",
                    imgSrc : require("../assets/img/snake.gif"),
                    ghLink : "https://github.com/elmurie/snake",
                    demoLink : "https://elmurie.github.io/snake/",
                    paragraph : "A fun Snake Minigame",
                    technologies: "ES6 Javascript modules"
                },
                { 
                    id : 14,
                    title : "Contacts",
                    class : "contacts",
                    heading : "How to reach me ",
                    gridArea: "7 / 7 / span 1 / span 1",
                    expandable : true,
                    bgColor : "#dd0100",
                    imgSrc : "",
                    ghLink : "",
                    demoLink : "",
                    paragraph : ""
                },
            ]
        }
    },
    computed: {
        classes() {
            return [
                "grid",
            ]
        }
    },
    mounted() {
        wrapGrid(this.$refs.grid, { 
                easing: 'backOut',
                stagger: 10,
                duration: 500,
            });
    }
}
</script>

<style lang="scss" scoped>

</style>