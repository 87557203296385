import Vue from 'vue'
import App from './App.vue'

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Star icons
library.add(faGithub, faLinkedin, faAt, faFileDownload, faGlobe);

Vue.component('font-awesome-icon', FontAwesomeIcon)
// /Fontawesome

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
