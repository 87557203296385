<template>
  <div id="app">
    <GridContainer/>
  </div>
</template>

<script>
import GridContainer from './components/GridContainer.vue'
export default {
  name: 'App',
  components: {
    GridContainer
  }
}
</script>

<style lang="scss">
@import './assets/style/common.scss';
</style>
