<template>
    <div @click="expand" class="card" :class="`${tiles.class}`" :style="`background-color : ${tiles.bgColor}`">
        <div class="content">
            <h2 class="card__title">{{tiles.title}}</h2>
            <div class="card__inner">
                <div class="card__inner_top">
                    <h2>{{tiles.heading}}</h2>
                </div>
                <div class="card__inner_bottom">
                    <img :class="{projGif : tiles.imgSrc != '' }" :src="tiles.imgSrc" alt="">
                    <div class="paragraph">
                        <p class="desc">{{tiles.paragraph}}</p>
                        <p class="tech" v-if="tiles.technologies">
                            Technologies : <strong>{{tiles.technologies}}</strong>
                        </p>
                        <ul class="cv-links" v-if="tiles.title == 'CV' ">
                            <a :href="'/docs/Simone Morieri CV 2024.pdf'" download="Simone_Morieri_CV"><button class="download"><font-awesome-icon :icon="['fas', 'file-download']" /> DOWNLOAD </button></a>
                        </ul>
                        <ul class="contacts-links" v-else-if="tiles.title == 'Contacts'">
                            <li>
                                <a href="https://github.com/elmurie" target="_blank">
                                    <font-awesome-icon class="contact-icon" :icon="['fab', 'github']" /> 
                                    <p class="contact-text">GitHub</p>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/simone-morieri/" target="_blank">
                                    <font-awesome-icon class="contact-icon" :icon="['fab', 'linkedin']" /> 
                                    <p class="contact-text">LinkedIn</p>
                                </a>
                            </li>
                            <li>
                                <a href="mailto:elmurie@gmail.com" target="_blank">
                                    <font-awesome-icon class="contact-icon" :icon="['fas', 'at']" /> 
                                    <p class="contact-text">E-Mail</p>
                                </a>
                            </li>
                        </ul>
                        <ul class="links" v-else>
                            <li class="proj-btn" v-if="tiles.ghLink"><a :href="tiles.ghLink" target="_blank"><font-awesome-icon :icon="['fab', 'github']"/> Code</a></li>
                            <li class="proj-btn" v-if="tiles.demoLink"><a :href="tiles.demoLink" target="_blank"><font-awesome-icon :icon="['fas', 'globe']" /> Demo</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Card',
    props : {
        tiles : Object
    },
    methods : {
        expand(block) {
            let mobileQuery = window.innerWidth;
            let notScroll = [ 10, 11, 13, 14];
            let target = block.target;
            let blocks = document.querySelectorAll('.card');
            if ( this.tiles.expandable ) {
                for ( let i = 0; i < blocks.length; i++ ) {
                    if ( blocks[i] == target ) {
                        blocks[i].classList.toggle('expand');
                    } else {
                        blocks[i].classList.remove('expand');
                    }
                }
                if ( mobileQuery < 780 ) {
                    return;
                } else {
                    if ( !notScroll.includes(this.tiles.id)) {
                        window.scrollTo(0, 0);
                    } else {
                        window.scrollTo(0, window.innerHeight);
                    }
                }
            } else {
                console.log('target is empty');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>